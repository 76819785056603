<div class="specialist-order-review container" *ngIf="!initLoading">
  <div *ngIf="getAlertsTitle()" style="margin-top: 10px; margin-bottom: 10px;">
    <div class="alert" style="cursor: pointer;" (click)="showAlerts = !showAlerts">
      <div class="key-alert-title">
        <strong>Key message alerts: </strong>
        <span *ngIf="!showAlerts">{{ getAlertsTitle() }}</span>
        <span *ngIf="showAlertsDots()">...</span>
        <span *ngIf="showExpandAlertsBtn()">
          <span *ngIf="!showAlerts" class="align-to-left glyphicon glyphicon-plus" aria-hidden="true"></span>
          <span *ngIf="showAlerts" class="align-to-left glyphicon glyphicon-minus" aria-hidden="true"></span>
        </span>
      </div>

      <div *ngIf="showAlerts" class="key-alerts-inner-container">
        <div *ngIf="patient?.allAlerts?.doctor?.length > 0">
          <strong>From Doctor:</strong>
          <p style="white-space: pre-line;">{{ patient?.allAlerts?.doctor }}</p>
        </div>
        <div *ngIf="patient?.allAlerts?.admin?.length > 0">
          <strong>From Admin:</strong>
          <p style="white-space: pre-line;">{{ patient?.allAlerts?.admin }}</p>
        </div>
      </div>
    </div>
  </div>
	<h1 class="brand-1">Review order {{orderId}}</h1>
	<h4>Ordered on: {{orderItem.creationDate | date}}</h4>
	<div class="row">
		<div class="col col-md-4">
			<div class="well well--black">
				<h3>Patient Details</h3>
				<p><strong>Patient ID:</strong> {{orderItem.patient.id}}</p>
				<p><strong>Name:</strong> {{orderItem.patient.displayName}}</p>
				<p><strong>Phone:</strong> {{orderItem.patient.phone}}</p>
				<p><strong>Email:</strong> {{orderItem.patient.email}}</p>
				<p><strong>DOB:</strong> {{orderItem.patient.dateOfBirth | date : "dd/MM/yyyy" }}</p>
				<p><strong>Age:</strong> {{dataService.getPatientBirthday(orderItem.patient.dateOfBirth) }}</p>
				<p><strong>Gender:</strong> {{orderItem.patient.gender}}</p>
				<p class="address">
					<strong>Address</strong>
					<app-address [address]="orderItem.patient.address"></app-address>
				</p>
				<p class="address" *ngIf="!!patient">
					<strong>GP Address</strong> <br />
					{{patient.preferences.gpName}}<br *ngIf="patient.preferences.gpName" />
					{{patient.preferences.gpPractice}}<br *ngIf="patient.preferences.gpPractice" />
					<app-address *ngIf="patient.preferences.gpAddress" [address]="patient.preferences.gpAddress">
					</app-address>
					<span *ngIf="!patient.preferences.gpAddress">Patient not provided</span>
				</p>
				<p *ngIf="!!patient"><strong>NHS:</strong> {{patient.settings.NHS_NUMBER ? patient.settings.NHS_NUMBER :
					'Not available'}}</p>
				<p *ngIf="!!patient"><strong>Primary Specialist:</strong> {{careSpecialist}}</p>
			</div>
		</div>
		<div class="col col-md-8">
			<div class="well well--white">
				<div *ngIf="orderItem.consultationDuration === 'IN_PERSON_MINUTE_00'" class="prescriber-details-container">
					<h4><b>*This order was conducted by a pharmacist</b></h4>
					<h5><u>Pharmacy details</u></h5>
					<div class="prescriber-details">
						<p><b>Pharmacy:</b> {{orderItem.prescribingPharmacist.displayName}}</p>
						<p><b>Pharmacist:</b> {{orderItem.workflow.PRESCRIBING_PHARMACIST_NAME}}</p>
						<p><b>Pharmacy email:</b> {{orderItem.prescribingPharmacist.email}}</p>
						<p><b>Pharmacy Contact number:</b> {{orderItem.prescribingPharmacist.phone}}</p>
					</div>
				</div>
<!--				<button *ngIf="!!patient" class="btn btn-default"-->
<!--					(click)="modalService.openModal('keyAlertModal')">Admin Key Alerts</button>-->
				<h3 class="brand-1">Order actions</h3>
				<div>
					<input type="checkbox" [(ngModel)]="readRecommendationsAndNotes" id="readRecommendationsAndNotes"
						[checked]="initCheckboxstatus" [disabled]="initCheckboxstatus" />
					<label class="radio-label" for="readRecommendationsAndNotes">
						I have reviewed the GP Notes and MDT Recommendations
					</label>
				</div>
				<div>
					<input type="checkbox" [(ngModel)]="contactedPatient" id="contactedPatient"
						[checked]="initCheckboxstatus" [disabled]="initCheckboxstatus" />
					<label class="radio-label" for="contactedPatient">I contacted the patient</label>
				</div>

				<div *ngIf="contactedPatient" style="margin-bottom: 1rem;">
					<textarea name="callProforma" [(ngModel)]="callProforma" rows="3" class="form-control"
						placeholder="Type conversation with the patient over the phone"
						[disabled]="orderItem.status !== 'AWAITING_SPECIALIST_CALL_PATIENT'"></textarea>
				</div>

				<div>
					<h6 *ngIf="orderItem.status === 'AWAITING_PATIENT_SPECIALIST_ALERT_REPLY'" class="wait-label">
						*Waiting for patient's reply
					</h6>
					<button class="btn btn-default" (click)="messagePatientModal.show()">
						{{specialistMessages.length ? 'View message history' : 'Message patient'}}
					</button>
				</div>

				<div class="decision-container" *ngIf="readRecommendationsAndNotes">
					<h5>Decision</h5>
					<input required type="radio" [(ngModel)]="approved" [value]="true" required name="decision"
						id="decision-approve" [disabled]="initDecisiontatus" />

					<label class="radio-label" for="decision-approve">Approve</label>
					<input required type="radio" [(ngModel)]="approved" [value]="false" required name="decision"
						id="decision-reject" style="margin-left: 1rem;" [disabled]="initDecisiontatus" />
					<label class="radio-label" for="decision-reject">Reject</label>

					<div style="margin-top: 1rem;" *ngIf="approved === false">
						<label>Reason for rejection?</label>
						<br>
						<input required type="radio" [(ngModel)]="rejectReason" [value]="'MEDICATION_OUT_OF_STOCK'"
							required name="rejectionOption" id="outOfStock" />
						<label class="radio-label" for="outOfStock">Medication stock is not available</label>
						<br>
						<input required type="radio" [(ngModel)]="rejectReason" [value]="'OTHER'" required
							name="rejectionOption" id="otherRejection" />
						<label class="radio-label" for="otherRejection">Other</label>

						<textarea *ngIf="rejectReason === 'OTHER'" name="rejectReason" placeholder="Type reason"
							[(ngModel)]="rejectReasonDescription" class="form-control"></textarea>
					</div>
				</div>

				<div *ngIf="approved === true" style="margin-top: 1rem;">
					<button class="btn btn-default" (click)="specialistPrescriptionModal.show()"
						[disabled]="orderItem.status === 'WAITING_FOR_TRACKING_CODE' || isPinkPrescriptionUploaded">
						{{prescriptionAdded ? 'Update Prescription' : 'Add Prescription'}}
						<span *ngIf="prescriptionAdded">
							&nbsp;&nbsp;
							<img src="/assets/vectors/success.svg" alt="" width="20">
						</span>
					</button>

					<div *ngIf="prescriptionAdded === true" style="margin-top: 1rem;">
						<span class="brand-1" style="font-style: italic;"
							*ngIf="orderItem.status === 'AWAITING_PATIENT_SPECIALIST_PRESCRIPTION_CONFIRMATION'">
							*You can upload the pink prescription after the patient confirms the proposed presciption
						</span>
						<br *ngIf="orderItem.status === 'AWAITING_PATIENT_SPECIALIST_PRESCRIPTION_CONFIRMATION'">
						<button class="btn btn-default" (click)="pinkPrescriptionModal.show()"
							[disabled]="!prescriptionBtnstatus.includes(orderItem.status)">
							Upload pink prescription
							<span *ngIf="prescriptionThumbnails.length">
								&nbsp;&nbsp;
								<img src="/assets/vectors/success.svg" alt="" width="20">
							</span>
						</button>
					</div>

					<form [formGroup]="prescriptionGroup" *ngIf="prescriptionThumbnails.length">
						<div class="prescription-image-container">
							<div *ngFor="let item of prescriptionThumbnails; index as i">
								<img *ngIf="orderItem.status !== 'WAITING_FOR_TRACKING_CODE'"
									src="/assets/vectors/close.svg" class="remove-btn" alt=""
									(click)="removeUploadedImage(i)">
								<img [src]="item" alt="" (click)="handleImagePreview(item)">
								<input type="text" class="form-control" [formControlName]="i" [id]="i" [name]="i"
									placeholder="Reference No.">
							</div>
						</div>
						<div *ngIf="prescriptionThumbnails.length"
							style="display: flex; justify-content: end; margin-top: 1rem;">
							<button type="submit" class="btn btn-primary" (click)="handleSubmitReview($event)">
								Complete Review
							</button>
						</div>
					</form>

				</div>
				<div *ngIf="approved === false" style="display: flex; justify-content: end; margin-top: 1rem;">
					<button class="btn btn-primary" (click)="handleSubmitReview($event)">
						Complete Review
					</button>
				</div>

			</div>

			<div *ngIf="pharmacyRejectionDetails" class="pharmacy-reject-reason">
				<p>This order already has had a prescription issued and the pharmacy
					<b>({{pharmacyRejectionDetails.pharmacy.displayName}})</b> had rejected it. <br>
					<span class="brand-1">Reason: {{pharmacyRejectionDetails.description}}</span>
				</p>
				<button class="btn btn-primary" (click)="rejectedPrescription.show()">View issued prescription</button>
			</div>

			<div *ngIf="orderItem.parentOrderItemId" class="repeat-reason">
				<h3 class="brand-1">
					Repeat order notes
				</h3>
				<div *ngIf="!!orderItem.parentOrderItemId" style="margin-bottom: 2rem; display: flex; gap: 1rem;">
					<button class="btn btn-primary" (click)="routeToPatientHistory()">View patient history</button>
					<button *ngIf="!!orderItem && !!orderItem.pictures && !!orderItem.pictures.length"
						class="btn btn-default" (click)="modalService.openModal('consultationDocuments')"
						style="margin-right: 1rem;">
						Consultation documents
					</button>
					<button *ngIf="!!orderItem && !!patientComplaint" class="btn btn-default"
						(click)="modalService.openModal('consultationNotes')" style="margin-right: 1rem;">
						Consultation notes
					</button>
				</div>


				<p *ngIf="changePrescriptionReason" class="brand-1">
					The patient has required a change to the prescription of a previous order. <br>
					Reason: <b>{{changePrescriptionReason}}</b>
				</p>

				<!-- <button *ngIf="!orderItem.isRepeatOrder" class="btn btn-primary" (click)="routeToOrderPreviewPage()">
					View linked order notes
				</button> -->

				<ng-container *ngIf="repeatOrderQuestionnaire">
					<h4 class="brand-1">Repeat questionnaire</h4>
					<div style="padding: 0 3rem;">
						<app-repeat-order-note *ngIf="repeatOrderQuestionnaire" [note]="repeatOrderQuestionnaire">
						</app-repeat-order-note>
					</div>
				</ng-container>

				<ng-container *ngIf="repeatOrderPegFormValues">
					<h4 class="brand-1">Peg Form</h4>
					<div class="note-score">Average: {{!!repeatOrderPegFormValues.average ?
						getPEGAverage(repeatOrderPegFormValues.average) : ''}}</div>
					<div *ngFor="let item of pegForm | keyvalue" class="patient-response">
						<h4>{{item.value}}</h4>
						<span>{{repeatOrderPegFormValues[item.key]}}</span>
					</div>
				</ng-container>

				<ng-container *ngIf="repeatOrderGadFormValues">
					<h4 class="brand-1">GAD Form</h4>
					<div class="note-score">Score: {{repeatOrderGadFormValues.score}}</div>
					<div *ngFor="let item of gadForm | keyvalue" class="patient-response">
						<h4>{{item.value.label}}</h4>
						<span>{{item.value.values[repeatOrderGadFormValues[item.key]]}}</span>
					</div>
				</ng-container>

        <ng-container *ngIf="repeatOrderSideEffectFormValues">
          <h4 class="brand-1">Side Effect Form</h4>
          <div class="note-score">Total: {{repeatOrderSideEffectFormValues?.average}}</div>
          <div *ngFor="let item of sideEffectForm | keyvalue" class="patient-response">
            <h4>{{item.value}}</h4>
            <span>{{repeatOrderSideEffectFormValues[item.key]}}</span>
          </div>
        </ng-container>
			</div>

			<div class="alert alert-{{orderItem | orderState}}">
				<strong>Order status:</strong> {{orderItem.status | orderStatus}}
			</div>
			<div class="well well--white" [class]="orderDetailsClassname">
				<div class="header-container" (click)="isRepeatOrder ? fetchParentOrder() :''">
					<h3 class="brand-1">
						{{isRepeatOrder ? 'Previous order details' : 'Order Details'}}
					</h3>
					<img *ngIf="isRepeatOrder" src="/assets/vectors/expand.svg" alt="">
				</div>
				<br>
				<ng-container>
					<h4 class="brand-1">Patient's Consent</h4>
					<div class="patient-hsitory">
						<p>Patient has given consent to the following:</p>
						<div style="display: flex; margin-bottom: 0.5rem;">
							<img src="/assets/vectors/success.svg" alt="" width="15" style="margin: 0 1rem auto 0;">
							<span>They are over 18 or over and a UK resident.
							</span>
						</div>
						<div style="display: flex; margin-bottom: 0.5rem;">
							<img src="/assets/vectors/success.svg" alt="" width="15" style="margin: 0 1rem auto 0;">
							<span>They have answered the online medical assessment truthfully with the knowledge that
								giving
								false information to obtain medicines could lead to a medicine being prescribed that
								is
								harmful to my health.</span>
						</div>
						<div style="display: flex; margin-bottom: 0.5rem;">
							<img src="/assets/vectors/success.svg" alt="" width="15" style="margin: 0 1rem auto 0;">
							<span>Any medicine prescribed is for their sole use.
							</span>
						</div>
						<div style="display: flex; margin-bottom: 0.5rem;">
							<img src="/assets/vectors/success.svg" alt="" width="15" style="margin: 0 1rem auto 0;">
							<span>They have agreed to read the patient information leaflet that will accompany the
								medicine.
							</span>
						</div>
						<div style="display: flex; margin-bottom: 0.5rem;">
							<img src="/assets/vectors/success.svg" alt="" width="15" style="margin: 0 1rem auto 0;">
							<span>They have read and understood the terms and conditions.
							</span>
						</div>

						<br>
						<h4 class="brand-1">Forms Filled During the Consultation</h4>

						<button *ngIf="orderItem.patient.files && orderItem.patient.files.length"
							class="btn btn-default" (click)="modalService.openModal('SCRModal')"
							style="margin-right: 1rem;">
							Patient documents
						</button>
						<button *ngIf="!!prevOrderItem && !!prevOrderItem.pictures && !!prevOrderItem.pictures.length"
							class="btn btn-default" (click)="modalService.openModal('prevConsultationDocuments')"
							style="margin-right: 1rem;">
							Previous Consultation documents
						</button>

						<button *ngIf="!!orderItem && !!orderItem.pictures && !!orderItem.pictures.length"
							class="btn btn-default" (click)="modalService.openModal('consultationDocuments')"
							style="margin-right: 1rem;">
							Consultation documents
						</button>

						<button *ngIf="!!orderItem && !!patientComplaint" class="btn btn-default"
							(click)="modalService.openModal('consultationNotes')" style="margin-right: 1rem;">
							Consultation notes
						</button>

						<button *ngIf="auditFormValues" class="btn btn-default" (click)="auditModal.show()"
							style="margin-right: 1rem;">
							AUDIT form
						</button>

						<button *ngIf="gadFormValues" class="btn btn-default" (click)="gadModal.show()"
							style="margin-right: 1rem;">
							GAD form
						</button>

						<button *ngIf="pegFormValues && !orderItem.parentOrderItemId" class="btn btn-default"
							(click)="pegModal.show()" style="margin-right: 1rem;">
							PEG form
						</button>
						<button *ngIf="proformaNote" class="btn btn-default" (click)="proformaModal.show()">
							MDT Proforma
						</button>
					</div>
				</ng-container>

				<h4 class="brand-1">
					{{isRepeatOrder
					? 'Recommendation from previous consultation'
					: 'MDT Recommendations'
					}}
				</h4>
				<div class="recommendations">
					<table *ngIf="mdtRecommendation" class="table">
						<tbody>
							<tr>
								<th>
									<p>Type</p>
								</th>
								<th>{{mdtRecommendation.medicationType}}</th>
							</tr>
							<tr>
								<th colspan="2">
									<p>Recommendations</p>
								</th>
							</tr>
							<tr>
								<td>Category</td>
								<td>{{mdtRecommendation.category}}</td>
							</tr>
							<tr>
								<td>Route of admin</td>
								<td>{{mdtRecommendation.route}}</td>
							</tr>
							<tr>
								<th colspan="2">
									<p>Patient preferences</p>
								</th>
							</tr>
							<tr>
								<td>Brand</td>
								<td>{{mdtRecommendation.brand}}</td>
							</tr>
							<tr>
								<td>Strain</td>
								<td>{{mdtRecommendation.strain}}</td>
							</tr>
							<tr>
								<td>Manufacturer</td>
								<td>{{mdtRecommendation.manufacturer}}</td>
							</tr>
							<tr>
								<th colspan="2">
									<p>Additional comments</p>
								</th>
							</tr>
							<tr>
								<td colspan="2">
									<p>{{mdtRecommendation.comments}}</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<ng-container *ngIf="!!previousPrescription">
					<h4 class="brand-1">
						Prescription from the previous order
					</h4>
					<div class="recommendations">
						<table class="table">
							<tbody>
								<tr>
									<th>
										<p>Type</p>
									</th>
									<th>Medical Cannabis</th>
								</tr>
								<tr>
									<th>
										<p>Name</p>
									</th>
									<th colspan="2">
										<p>{{previousPrescription.productName}}</p>
									</th>
								</tr>
								<tr>
									<th>
										<p>Medicine Profile</p>
									</th>
									<th colspan="2">
										<p>{{previousPrescription.medicineProfile}}</p>
									</th>
								</tr>
								<tr>
									<td>Category</td>
									<td>{{previousPrescription.category}}</td>
								</tr>
								<tr>
									<td>Route of admin</td>
									<td>{{previousPrescription.administrationRoute}}</td>
								</tr>
								<tr>
									<th colspan="2">
										<p>Patient preferences</p>
									</th>
								</tr>
								<tr>
									<td>Brand</td>
									<td>{{previousPrescription.brandName}}</td>
								</tr>
								<tr>
									<td>Strain</td>
									<td>{{previousPrescription.strain}}</td>
								</tr>
								<tr>
									<td>Manufacturer</td>
									<td>{{previousPrescription.manufacture}}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</ng-container>



				<ng-container *ngIf="isRepeatOrder; else notRepeatOrder">
					<app-gp-decisions *ngIf="prevOrderItem" [orderNotes]=" prevOrderItem.orderNotes"></app-gp-decisions>
				</ng-container>

				<ng-template #notRepeatOrder>
					<app-gp-decisions [orderNotes]="orderItem.orderNotes"></app-gp-decisions>
				</ng-template>

				<ng-container *ngIf="!!gpNotes">
					<h4 class="brand-1">Consultation notes</h4>
					<ng-container *ngIf="!!gpNotes" [ngSwitch]="orderItem.consultationDuration">
						<app-order-notes *ngSwitchCase="'MINUTES_30'" [orderstatus]="orderItem.status"
							[orderId]="orderItem.id"></app-order-notes>
						<p *ngSwitchCase="'MINUTES_15'">{{gpNotes?.doctorComments}}</p>
						<app-pharmacy-conultation-note *ngSwitchCase="'IN_PERSON_MINUTE_00'" [gpNote]="gpNotes">
						</app-pharmacy-conultation-note>
					</ng-container>
				</ng-container>
			</div>
		</div>
	</div>
	<app-chat-widget *ngIf="orderItem !== undefined" [orderId]="orderItem.id"></app-chat-widget>
</div>

<app-admin-key-alert-modal *ngIf="!!patient" [patient]="patient"></app-admin-key-alert-modal>
<app-consultation-documents-modal *ngIf="!!orderItem && !!orderItem.pictures && !!orderItem.pictures.length"
	[documents]="orderItem.pictures"></app-consultation-documents-modal>
<app-consultation-notes-modal *ngIf="!!orderItem && !!patientComplaint" [notes]="patientComplaint">
</app-consultation-notes-modal>
<app-consultation-documents-modal *ngIf="!!prevOrderItem && !!prevOrderItem.pictures && !!prevOrderItem.pictures.length"
	[modalId]="'prevConsultationDocuments'" [documents]="prevOrderItem.pictures"></app-consultation-documents-modal>

<div bsModal #messagePatientModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
	<div *ngIf="!initLoading" class="modal-dialog message-modal">
		<div class="modal-content" *ngIf="!!patient">
			<div class="modal-header">
				<button type="button" class="close" (click)="messagePatientModal.hide()" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
				<h4 class="modal-title">{{patient.displayName}}</h4>
			</div>
			<div class="modal-body">
				<div *ngIf="specialistMessages.length; else pendingConvo" class="message-section">
					<table class="table table-hover">
						<thead>
							<tr>
								<th>Question</th>
								<th>Answer</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let item of specialistMessages">
								<td>
									<div class="date-bubble">{{item.creationDate | date : 'dd-MMM-yyyy HH:mm'}}</div>
									<span>{{getMessage(item.questionAnswer).question}}</span>
								</td>
								<td>
									<div class="date-bubble">{{item.updateDate | date : 'dd-MMM-yyyy HH:mm'}}</div>
									<span
										[class]="orderItem.status === 'AWAITING_PATIENT_SPECIALIST_ALERT_REPLY' ? 'waiting-bubble' : ''">
										{{item.patient ? getMessage(item.questionAnswer).answer : 'Waiting for reply.'}}
									</span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>

				<ng-template #pendingConvo>
					<div style="text-align: center; margin-bottom: 2rem;">
						Your conversation history will appear here.
					</div>
				</ng-template>
				<form #messageForm="ngForm" class="message-form">
					<textarea class="form-control" name="message" [(ngModel)]="message" required rows="10"></textarea>
					<button class="btn btn-primary" [disabled]="messageForm.invalid" type="button"
						(click)="sendMessage(messageForm)">
						Send
					</button>
				</form>
			</div>
		</div>
	</div>
</div>

<div bsModal #specialistPrescriptionModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
	<app-prescription-builder *ngIf="!initLoading" [mdtRecommendation]="mdtRecommendation" [doctorId]="orderItem.prescribingSpecialistId"
		[gpRecommendation]="gpRecommendation" [isPrescriptionAdded]="prescriptionAdded" [orderId]="prescriptionAdded ? orderId : null" (hide)="specialistPrescriptionModal.hide()"
		(sendPrescription)="handleSavePrescription($event)" [order]="orderItem">
	</app-prescription-builder>
</div>

<div bsModal #rejectedPrescription="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
	<div *ngIf="!initLoading && pharmacyRejectionDetails" class="modal-dialog message-modal">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" (click)="rejectedPrescription.hide()" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
				<h4 class="modal-title">Rejected prescription</h4>
			</div>
			<div class="modal-body rejected-prescription-list">
				<div *ngFor="let item of pharmacyRejectionDetails.medication" class="prescription">
					<div class="prescription-header">
						<h3>
							<b>#{{item.formulary.id}}: {{item.formulary.productName}}</b>
						</h3>
						<span *ngIf="item.formulary.active" class="label label-primary">Active</span>
						<span *ngIf="!item.formulary.active" class="label label-warning">Disabled</span>
						<span style="margin-left: auto;"><b>Prescribed quantity:</b> {{item.quantity}}</span>
					</div>
					<div class="prescription-attributes">
						<span><b>Category</b>: {{item.formulary.category}}</span>
						<span><b>Administration route</b>: {{item.formulary.administrationRoute}}</span>
						<span><b>Stock status</b>: {{item.formulary.stockStatus | stockStatus}}</span>
						<span><b>Brand</b>: {{item.formulary.brandName}}</span>
						<span><b>Manufacturer</b>: {{item.formulary.manufacture}}</span>
						<span><b>Strain</b>: {{item.formulary.strain}}</span>
						<span><b>Available quantity</b>: {{item.formulary.availQuantity}}</span>
						<span><b>Origin country</b>: {{item.formulary.originCountry}}</span>
						<span><b>Original strain</b>: {{item.formulary.originalStrain}}</span>
						<span><b>Medicine profile</b>: {{item.formulary.medicineProfile}}</span>
						<span><b>Benefits</b>: {{item.formulary.benifits}}</span>
						<span><b>Production note</b>: {{item.formulary.productionNote}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div bsModal #prescriptionPinModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
	<div *ngIf="!initLoading" class="modal-dialog modal-md">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" (click)="prescriptionPinModal.hide()" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
				<h4 class="modal-title">Prescription Pin</h4>
			</div>
			<div class="modal-body">
				<form #prescriptionPinForm="ngForm" (ngSubmit)="handleSubmitPrescription(prescriptionPinForm)"
					novalidate autocomplete="off">
					<label for="deliveryNote">Delivery Note</label>
					<input type="text" class="form-control" name="deliveryNote" id="deliveryNote" disabled
						placeholder="Auto generated delivery note goes here" />

					<label for="prescriptionPin" [style.margin-top]="'1rem'">Pin</label>
					<input type="text" class="form-control pw" [(ngModel)]="prescriptionPin" name="prescriptionPin" id="prescriptionPin" autocomplete="off">

					<button type="submit" class="btn btn-primary submit-btn" style="margin-top: 1rem; width: 100%;">
						Submit Prescription
					</button>
				</form>
			</div>
		</div>
	</div>
</div>

<div bsModal #pinkPrescriptionModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
	<div *ngIf="!initLoading" class="modal-dialog modal-md">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" (click)="pinkPrescriptionModal.hide()" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
				<h4 class="modal-title">Upload Pink Prescription</h4>
			</div>
			<div class="modal-body">
				<form (ngSubmit)="handleSubmitPinkPrescription()" novalidate autocomplete="off">
					<label for="pinkPrescriptions">Upload files</label>
					<div class="file-input-container">
						<input #fileInput type="file" accept="image/png, image/jpeg" name="pinkPrescriptions"
							id="pinkPrescriptions" (change)="updatePinkPrescriptions($event)" required>
						<button class="btn btn-info" type="button" (click)="openFileUploader()">Upload
							files</button>
						<span>{{pinkPlaceholder}}</span>
					</div>

					<div *ngIf="prescriptionThumbnails.length > 0" class="prescription-image-container">
						<div *ngFor="let item of prescriptionThumbnails; index as i">
							<img src="/assets/vectors/close.svg" class="remove-btn" alt=""
								(click)="removeUploadedImage(i)">
							<img [src]="item" alt="">
						</div>
					</div>

					<div [style.margin]="'2rem 0 0'">
						<h5><b>Scan this QR to upload from mobile</b></h5>
						<div class="qr-container">
							<qrcode [qrdata]="qrUrl" [allowEmptyString]="true" [cssClass]="'center'"
								[colorDark]="'#000000ff'" [colorLight]="'#ffffffff'" [elementType]="'svg'"
								[errorCorrectionLevel]="'M'" [scale]="1" [title]="'A custom title attribute'"
								[width]="200"></qrcode>
						</div>
					</div>

					<button type="submit" [disabled]="pinkPrescriptions === undefined"
						class="btn btn-primary submit-btn" style="margin-top: 1rem; width: 100%;">
						Upload prescription
					</button>
				</form>
			</div>
		</div>
	</div>
</div>

<div bsModal #previewImgModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog modal-lg">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" (click)="previewImgModal.hide()" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
				<h4 class="modal-title">Prescription preview</h4>
			</div>
			<div class="modal-body">
				<div class="img-preview">
					<img [src]="selectedImage" alt="">
				</div>
			</div>
		</div>
	</div>
</div>

<app-patient-scr-documents-modal *ngIf="orderItem" [order]="orderItem"></app-patient-scr-documents-modal>

<div bsModal #auditModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
	<div *ngIf="auditFormValues" class="modal-dialog modal-lg">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" (click)="auditModal.hide()" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
				<h4 class="modal-title">AUDIT Feedback</h4>
			</div>
			<div class="modal-body">
				<div class="note-score">Score: {{auditFormValues.score}}</div>
				<div *ngFor="let item of auditForm | keyvalue" class="patient-response">
					<h4>{{item.value.label}}</h4>
					<span>{{item.value.values[auditFormValues[item.key]]}}</span>
				</div>
			</div>
		</div>
	</div>
</div>

<div bsModal #pegModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
	<div *ngIf="pegFormValues" class="modal-dialog modal-lg">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" (click)="pegModal.hide()" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
				<h4 class="modal-title">PEG Feedback</h4>
			</div>
			<div class="modal-body">
				<div class="note-score">Average: {{getPEGAverage(pegFormValues.average)}}</div>
				<div *ngFor="let item of pegForm | keyvalue" class="patient-response">
					<h4>{{item.value}}</h4>
					<span>{{pegFormValues[item.key]}}</span>
				</div>
			</div>
		</div>
	</div>
</div>

<div bsModal #proformaModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
	<app-mdt-proforma-modal *ngIf="proformaNote !== undefined" [proformaNotes]="proformaNote"
		(hideModal)="proformaModal.hide()">
	</app-mdt-proforma-modal>
</div>

<div bsModal #gadModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
	<div *ngIf="gadFormValues" class="modal-dialog modal-lg">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" (click)="gadModal.hide()" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
				<h4 class="modal-title">GAD Feedback</h4>
			</div>
			<div class="modal-body">
				<div class="note-score">Score: {{gadFormValues.score}}</div>
				<div *ngFor="let item of gadForm | keyvalue" class="patient-response">
					<h4>{{item.value.label}}</h4>
					<span>{{item.value.values[gadFormValues[item.key]]}}</span>
				</div>
			</div>
		</div>
	</div>
</div>
